import { Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { MdOutlineUpdate } from 'react-icons/md';

import { Checkmark } from '../../../assets/Images';
import { Deal } from '../../../gql/generated/graphql';
import { sameEmail } from '../../../utils/customer';
import { dashboardDealInfoAtom } from '../Dashboard';
import { documentsSigned, isDevActiveAtom } from './DashboardDevToolbar';
import { dealSwitcherOptionAtom } from './DealSwitcher';
import WhoIsSigningModal from './SigningModal/WhoIsSigningModal';

interface SignCompleteProps {
  hasCobuyer: boolean;
  isCobuyer: boolean;
}

const SignComplete = ({ hasCobuyer, isCobuyer }: SignCompleteProps) => {
  return (
    <Flex alignItems="center" gap="7px">
      <Image src={Checkmark} boxSize="25px" />
      <Text textTransform="uppercase" fontSize="14px" fontWeight="semibold">
        {hasCobuyer
          ? isCobuyer
            ? 'CO-BUYER E-SIGNING COMPLETE (WAITING FOR BUYER TO SIGN)'
            : 'BUYER E-SIGNING COMPLETE (WAITING FOR CO-BUYER TO SIGN)'
          : 'E-SIGNING COMPLETE'}
      </Text>
    </Flex>
  );
};

interface SignButtonsProps {
  deal: Deal;
  sentForSignatures: boolean;
  handleSign: () => void;
}

const SignButtons = ({ deal, sentForSignatures, handleSign }: SignButtonsProps) => {
  const isDevActive = useAtomValue(isDevActiveAtom);
  const devDocumentsSigned = useAtomValue(documentsSigned);
  const shareEmail = sameEmail(deal?.customer, deal?.cobuyer);
  const dealSwitcherOptions = useAtomValue(dealSwitcherOptionAtom);
  const setDealId = useSetAtom(dashboardDealInfoAtom);
  const [isWhoIsSigningModalOpen, setIsWhoIsSigningModalOpen] = useState(false);

  const isCobuyer = deal?.isCobuyer ?? false;
  const hasCobuyer = !!deal?.cobuyer;
  const startedSigning = !!(isCobuyer
    ? deal?.cobuyer?.signature_data
    : deal?.customer?.signature_data);
  const doneSigning =
    (isCobuyer ? deal?.cobuyer?.finished_signing : deal?.customer?.finished_signing) ||
    (isDevActive && devDocumentsSigned);

  const setDealOption = (cobuyer: boolean) => {
    const option = dealSwitcherOptions.find(
      (op) => op.value.id === deal?.id && op.value.isCobuyer === cobuyer,
    )?.value;

    if (option) {
      setDealId(option);
    }
  };

  const copy = `${
    !sentForSignatures
      ? `Preparing Documents ${isCobuyer ? 'for Co-Buyer' : 'for Buyer'}`
      : startedSigning
      ? 'Continue Signing'
      : 'Review & Sign'
  }`;

  if (shareEmail) {
    const handleConfirm = () => {
      setIsWhoIsSigningModalOpen(false);
      handleSign();
    };

    const handleSwitch = (cobuyerSigning: boolean) => setDealOption(!cobuyerSigning);

    return (
      <>
        <Flex
          justifyContent={{ base: 'center', md: 'flex-start' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          <Flex flexDir="column" alignItems={doneSigning ? 'flex-start' : 'center'} gap="10px">
            {doneSigning ? (
              <SignComplete hasCobuyer={hasCobuyer} isCobuyer={isCobuyer} />
            ) : (
              <Button
                leftIcon={
                  !sentForSignatures ? <Icon as={MdOutlineUpdate} w={6} h={6} /> : undefined
                }
                textTransform="uppercase"
                isDisabled={!sentForSignatures}
                onClick={() => setIsWhoIsSigningModalOpen(true)}
              >
                {copy}
              </Button>
            )}
            <Button
              textDecor="underline"
              _hover={{ opacity: 0.8, shadow: 'md' }}
              mt={2}
              ml={doneSigning ? '32px' : 0}
              variant="link"
              fontWeight="bold"
              onClick={() => handleSwitch(isCobuyer)}
            >
              SIGN AS THE {isCobuyer ? '' : 'CO-'}BUYER
            </Button>
          </Flex>
        </Flex>
        <WhoIsSigningModal
          isOpen={isWhoIsSigningModalOpen}
          onClose={() => setIsWhoIsSigningModalOpen(false)}
          deal={deal}
          onConfirm={handleConfirm}
          handleSwitch={handleSwitch}
        />
      </>
    );
  }

  if (doneSigning) {
    return <SignComplete hasCobuyer={hasCobuyer} isCobuyer={isCobuyer} />;
  }

  const buttonCopy = !sentForSignatures
    ? 'Preparing Documents'
    : startedSigning
    ? 'Continue Signing'
    : 'Review & Sign';

  return (
    <Flex flexDir={{ base: 'column', md: 'row' }} alignItems="center" gap="10px">
      <Button
        leftIcon={!sentForSignatures ? <Icon as={MdOutlineUpdate} w={6} h={6} /> : undefined}
        textTransform="uppercase"
        isDisabled={!sentForSignatures}
        onClick={handleSign}
      >
        {buttonCopy}
      </Button>
      {hasCobuyer && (
        <Flex alignItems="center" gap="5px">
          <Icon as={AiFillInfoCircle} boxSize="22.5px" />
          <Text>
            You're signing as a <strong>{isCobuyer && 'Co-'}Buyer</strong>
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default SignButtons;
