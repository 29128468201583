import { Flex, Spinner, Text } from '@chakra-ui/react';

interface Props {
  year: string;
  make: string;
  model: string;
  vin: string;
  infoLoading: boolean;
}

const VehicleInfo = ({ year, make, model, vin, infoLoading }: Props) => {
  return (
    <Flex
      direction="column"
      w="full"
      p={5}
      borderRadius={5}
      bgColor="brightGray"
      alignItems="center"
      justifyContent="center"
    >
      {infoLoading ? (
        <Spinner size="md" />
      ) : (
        <Flex alignItems="center" mb={1}>
          <Text fontSize="2xl" as="b" mr={2}>
            {`${year} ${make}`} {model}
          </Text>
        </Flex>
      )}
      <Text as="b" pr={1} fontSize="14px">
        VIN: {infoLoading ? <Spinner size="sm" /> : vin}
      </Text>
    </Flex>
  );
};

export default VehicleInfo;
