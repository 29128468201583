import { ListItem, UnorderedList } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

export const StrongPasswordTooltipExplanation = (
  <UnorderedList>
    <ListItem>Between 8-30 characters</ListItem>
    <ListItem>One uppercase letter</ListItem>
    <ListItem>One lowercase letter</ListItem>
    <ListItem>One number</ListItem>
    <ListItem>Not the previous 2 passwords</ListItem>
  </UnorderedList>
);

const VerificationErrors = ['Max attempt limit reached', 'SSN does not match'];

export const getVerificationError = (error: string, setError: Dispatch<SetStateAction<string>>) => {
  if (VerificationErrors.some((err) => error.includes(err))) {
    setError(error);
    return;
  }
  setError('There was an error. Please try again.');
};
