import { Box, Container, Image, Link } from '@chakra-ui/react';
import { useEffect } from 'react';

import { CouchSleep } from '../../assets/Images';
import Description from '../../components/Description';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import Label from '../../components/formComponents/Label';
import { resetLocalStorage } from '../../services/localStorage';
import getSearchPhoneNumbers from '../../utils/getSearchPhoneNumbers';
import FaqsWrapper from './FaqsWrapper';
import InTheMeantime from './InTheMeantime';

interface NicelyDoneProps {
  copy: {
    subtitle: string;
    whatsNext1: string;
    whatsNext2: string;
  };
  payoffFailure?: boolean;
}

const NicelyDone = ({ copy, payoffFailure = false }: NicelyDoneProps) => {
  const phoneNumber = getSearchPhoneNumbers();

  useEffect(() => {
    resetLocalStorage({ removeGuid: true, removeAccessToken: true });
  }, []);

  return (
    <Container textAlign="left">
      <TitleContainer>
        <Title maxW={{ base: '256px', md: 'none' }}>
          {payoffFailure ? 'We Were Unable to Find Your Payoff' : 'Nicely Done!'}
        </Title>
      </TitleContainer>
      {!payoffFailure && (
        <Subtitle
          w={{ base: '90%', lg: '700px' }}
          m={{ base: '0 auto 20px', lg: '0 auto' }}
          textAlign="center"
        >
          {copy.subtitle}
        </Subtitle>
      )}
      <Box overflow="hidden" w="100%" display={{ base: 'block', md: 'none' }}>
        <Image
          minW="550px"
          ml="-35px"
          src={CouchSleep}
          alt="The Lease End Purple Guy on the couch asleep with his cat."
        />
        <Box
          borderTop="3px solid"
          borderTopColor="midnightBlueDark"
          bgColor="grayBackground"
          h="10vh"
          w="150vw"
          m="-70px -50vw 0px"
          zIndex="-1"
        />
      </Box>
      <Box
        bgColor={{ base: 'grayBackground', lg: 'white' }}
        w={{ base: '100%', lg: '615px' }}
        m={{ base: '0 auto', lg: '30px auto' }}
        p="30px 20px"
        h="auto"
        textAlign="left"
      >
        {payoffFailure ? (
          <Description m={{ base: '10px auto', lg: '15px auto' }}>
            Our advisors couldn’t validate your payoff amount or good-through date with the
            information provided. We’ll be in contact soon to resolve this.
          </Description>
        ) : (
          <>
            <Label fontSize={{ base: 'unset', lg: '20px' }}>WHAT'S NEXT</Label>
            <Description m={{ base: '10px auto', lg: '15px auto' }}>{copy.whatsNext1}</Description>
            <Description m={{ base: '10px auto', lg: '15px auto' }}>{copy.whatsNext2}</Description>
          </>
        )}
        <Description>
          <br />
          If you have any questions at all feel free to call us at
          <br />
          <b>
            <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link>
          </b>
          .
        </Description>
      </Box>
      <InTheMeantime />
      <FaqsWrapper />
      <Box display={{ base: 'none', md: 'block' }}>
        <Image
          w={{ base: '650px', lg: '1000px' }}
          h="auto"
          m="0 auto"
          src={CouchSleep}
          alt="The Lease End Purple Guy on the couch asleep with his cat."
        />
        <Box
          borderTop="3px solid"
          borderTopColor="midnightBlueDark"
          bgColor="grayBackground"
          h={{ base: '50vh', lg: '20vh' }}
          w="147vw"
          m={{ base: '-71px -50vw 0px', lg: '-110px -50vw 0px' }}
          zIndex="-1"
        />
      </Box>
      ;
    </Container>
  );
};

export default NicelyDone;
