import { Cars, Suvs, Trucks } from '../../assets/Images';
import { VEHICLE_COLORS } from '../../constants/vehicleColors';
import { vehicleType } from '../../constants/vehicleType';
import { Car } from '../../gql/generated/graphql';

export const carIconFromCarData = (car: Car | undefined | null) => {
  let vehicles = Cars;

  if (car?.vehicle_type === vehicleType.MPV) {
    vehicles = Suvs;
  } else if (car?.vehicle_type?.trim() === vehicleType.TRUCK) {
    vehicles = Trucks;
  }

  const color = car?.color || VEHICLE_COLORS.White;

  const vehicle = vehicles.find((val) => val.value === color);

  return vehicle?.key;
};
