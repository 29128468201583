import { Box, VStack } from '@chakra-ui/react';
import { useState } from 'react';

import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import {
  KbbVinResult,
  Maybe,
  useTemporaryInfoUpdateMutation,
} from '../../../../gql/generated/graphql';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import SelectTrim from './components/SelectTrim';
import SomethingsNotRight from './components/SomethingsNotRight';
import VehicleInfo from './components/VehicleInfo';

const LooksRightItem = () => {
  const [refresh, setRefresh] = useState(false);

  const { info, infoLoading } = useTempInfo(undefined, refresh);
  const { updateState, index, setOpenAccordionIndex } = useLEAccordionItemContext();
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();

  const handleNext = async (vehicle: Maybe<KbbVinResult>) => {
    updateState({ status: 'loading' });

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: {
            kbb_vehicle_id: vehicle?.vehicleId,
            kbb_vehicle_name: vehicle?.vehicleName,
            kbb_trim_name: vehicle?.trimName,
            kbb_all_options: vehicle?.vehicleOptions,
          },
        },
      },
    });

    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  return (
    <Box color="leaseEndBlue" mt={2}>
      <VStack>
        <VehicleInfo
          year={info?.data.year ?? ''}
          make={info?.data.make ?? ''}
          model={info?.data.model ?? ''}
          vin={info?.data.vin ?? ''}
          infoLoading={infoLoading}
        />

        <SomethingsNotRight info={info} setRefresh={setRefresh} />

        <SelectTrim
          initialTrim={info?.data.kbb_trim_name ?? ''}
          vin={info?.data.vin ?? ''}
          handleNext={handleNext}
        />
      </VStack>
    </Box>
  );
};

export default LooksRightItem;
