import { Box, Button, Container, Icon, IconButton, Link, Text } from '@chakra-ui/react';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useJwt } from 'react-jwt';
import { Link as RRLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import DividerWithText from '../../components/DividerWithText';
import SimpleFooter from '../../components/SimpleFooter';
import Input from '../../components/formComponents/Input';
import { LDFlags } from '../../constants/flags';
import {
  CREATE_ACCOUNT,
  DASHBOARD,
  FORGOT_PASSWORD,
  LINK_MY_ACCOUNT,
  ONE_CLICK_LOGIN,
} from '../../constants/urls';
import { useGetUnlinkedDealsLazyQuery } from '../../gql/generated/graphql';
import { useFlag, useTempInfo } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { ResetWsContext } from '../../providers/AuthorizedApolloProvider';
import { login, parseTokenFromHash } from '../../services/auth0';
import { logger } from '../../services/sentry';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { emailValidationRequired } from '../../utils/validation/email';
import Title from './components/Title';

interface Values {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: emailValidationRequired,
  password: Yup.string().required('Please enter a value'),
});

const AccountLogin = () => {
  const { info } = useTempInfo('network-only');
  const { resetWebSocket } = useContext(ResetWsContext);
  const [accessToken, setAccessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const { decodedToken, isExpired } = useJwt<{ sub: string }>(accessToken || '');
  const history = useHistory();
  const inDashVerification = useFlag(LDFlags.SSN_DASH_VERIFICATION);

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const [unlinkedDealsLazyQuery] = useGetUnlinkedDealsLazyQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    onCompleted: ({ getUnlinkedDeals }) => {
      if (!inDashVerification && getUnlinkedDeals && getUnlinkedDeals.length > 0) {
        history.replace(LINK_MY_ACCOUNT);
      } else if (info?.data?.last_url) {
        history.replace(info?.data?.last_url);
      } else {
        history.replace(DASHBOARD);
      }
    },
  });

  useEffect(() => {
    if (!!decodedToken && !isExpired) {
      rudderanalytics.identify({ auth0_id: decodedToken.sub });
      unlinkedDealsLazyQuery();
    }
  }, [decodedToken, isExpired]);

  const alreadyRan = useRef(false);
  useEffect(() => {
    if (!window.location.hash || alreadyRan.current) {
      return;
    }

    alreadyRan.current = true;

    parseTokenFromHash({
      hash: window.location.hash,
      callback: (err, authResult) => {
        if (err || !authResult?.accessToken) {
          logger.error('AccountLogin.tsx', '', err, null);
          return;
        }
        resetWebSocket();
        setAccessToken(authResult.accessToken);
      },
    });
  }, [window.location.hash]);

  const handleSubmit = (values: Values, helpers: FormikHelpers<Values>) => {
    setError('');

    login({
      email: values.email,
      password: values.password,
      callback: (err) => {
        helpers.setSubmitting(false);
        const errorMessage = err?.description ?? err?.toString() ?? 'Invalid code';

        rudderanalytics.track(RudderEvent.Error, {
          error_message: errorMessage,
          email: values.email,
        });

        if (err) {
          setError(errorMessage);
        }
      },
    });
  };

  return (
    <>
      <Container pt={10}>
        <Title>Welcome Back to Lease End</Title>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form>
                <Box bg="white" p={8} rounded="lg" maxW="400px" mx="auto">
                  <Input
                    label="Email"
                    name="email"
                    placeholder="Email"
                    autoFocus
                    _container={{ mb: 4 }}
                  />
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    name="password"
                    placeholder="Password"
                    _container={{ mb: 2 }}
                    icon={
                      <IconButton
                        variant="unstyled"
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="Hide/Show password"
                        icon={<Icon as={showPassword ? FaEye : FaEyeSlash} mt={1} />}
                      />
                    }
                  />
                  <Link w="100%" as={RRLink} to={FORGOT_PASSWORD}>
                    Forgot password?
                  </Link>
                  <Button w="100%" mt={6} type="submit" isLoading={isSubmitting}>
                    LOGIN
                  </Button>
                  {error && (
                    <Text fontSize="14px" mt={2} color="leaseEndRed" textAlign="center">
                      {error}
                    </Text>
                  )}
                  <DividerWithText text="or" my={4} />
                  <Button
                    w="100%"
                    variant="secondary"
                    onClick={() =>
                      history.push({ pathname: ONE_CLICK_LOGIN, state: { email: values.email } })
                    }
                  >
                    SIGN IN WITH ONE-TIME LINK
                  </Button>
                  <Box textAlign="center" mt={4}>
                    Don't have an account?{' '}
                    <Link as={RRLink} to={CREATE_ACCOUNT}>
                      Click to get started!
                    </Link>
                  </Box>
                </Box>
                <SimpleFooter />
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default AccountLogin;
