import { MAIN_NUMBER } from '../constants/contact';
import { CookieKeys, getCookie } from '../hooks/useCookie';
import { FLOW_ENTRY_PARAMS } from '../pages/flowEntryPages/utils';

const getSearchPhoneNumbers = () => {
  const savedParams = getCookie<string>(CookieKeys.QUERY_PARAMS);
  let phone = MAIN_NUMBER;

  if (savedParams) {
    const oldParams = new URLSearchParams(savedParams);
    phone = oldParams.get(FLOW_ENTRY_PARAMS.CONTACT_US) ?? MAIN_NUMBER;
  }

  return phone;
};

export default getSearchPhoneNumbers;
