import { Box, Center, Collapse, Divider, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import ButtonedRadioGroup from '../../../components/ButtonedRadioGroup';
import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { AddressInput, Label, NumberInput } from '../../../components/formComponents';
import MonthsAndYears, {
  shouldRequirePrevious,
} from '../../../components/monthsAndYears/MonthsAndYears';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { OWNERSHIP_TYPE } from '../../../constants/addresses';
import { useTemporaryInfoUpdateMutation } from '../../../gql/generated/graphql';
import { useTempInfo } from '../../../hooks';
import { capitalizeFirstLetter } from '../../../utils/text/text';
import {
  CobuyerResidenceValues,
  cobuyerResidenceInitialValues,
  cobuyerResidenceValidationSchema,
} from '../utils';

const CobuyerResidence = () => {
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();
  const { updateState, isExpanded, setOpenAccordionIndex, index } = useLEAccordionItemContext();
  const { info } = useTempInfo('network-only', isExpanded);
  const tempInfoData = info?.data;

  const [liveTogether, setLiveTogether] = useState(true);
  const [showPreviousResidence, setShowPreviousResidence] = useState<boolean>(false);

  useEffect(() => {
    setShowPreviousResidence(
      shouldRequirePrevious(
        tempInfoData?.cobuyer_years_at_job,
        tempInfoData?.cobuyer_months_at_job ?? 0,
      ),
    );
  }, [tempInfoData]);

  const [showMonthlyCost, setShowMonthlyCost] = useState(
    tempInfoData?.cobuyer_primary_residence_type !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT,
  );

  useEffect(() => {
    if (tempInfoData?.cobuyer_address_line) {
      setLiveTogether(false);
    }
  }, [tempInfoData?.cobuyer_address_line]);

  const sameAsBuyerData = () => {
    const resetedCobuyerAddressData = {
      cobuyer_address_line: null,
      cobuyer_address_line_2: null,
      cobuyer_city: null,
      cobuyer_state: null,
      cobuyer_county: null,
      cobuyer_zip: null,
    };

    const resetedCobuyerResidenceData = {
      cobuyer_primary_residence_type: null,
      cobuyer_primary_residence_years: null,
      cobuyer_primary_residence_months: null,
      cobuyer_primary_residence_monthly_payment: null,
      cobuyer_secondary_residence_years: null,
      cobuyer_secondary_residence_months: null,
      cobuyer_previous_address_line: null,
      cobuyer_previous_address_line_2: null,
      cobuyer_previous_city: null,
      cobuyer_previous_state: null,
      cobuyer_previous_zip: null,
      cobuyer_previous_county: null,
    };

    const buyerAddressData = {
      cobuyer_address_line: info?.data?.address_line,
      cobuyer_address_line_2: info?.data?.address_line_2,
      cobuyer_city: info?.data?.city,
      cobuyer_state: info?.data?.state,
      cobuyer_county: info?.data?.county,
      cobuyer_zip: info?.data?.zip,
    };

    const buyerResidenceData = {
      cobuyer_primary_residence_type: info?.data?.primary_residence_type,
      cobuyer_primary_residence_years: info?.data?.primary_residence_years,
      cobuyer_primary_residence_months: info?.data?.primary_residence_months,
      cobuyer_primary_residence_monthly_payment: info?.data?.primary_residence_monthly_payment,
      cobuyer_secondary_residence_years: info?.data?.secondary_residence_years,
      cobuyer_secondary_residence_months: info?.data?.secondary_residence_months,
      cobuyer_previous_address_line: info?.data?.previous_address_line,
      cobuyer_previous_address_line_2: info?.data?.previous_address_line_2,
      cobuyer_previous_city: info?.data?.previous_city,
      cobuyer_previous_state: info?.data?.previous_state,
      cobuyer_previous_zip: info?.data?.previous_zip,
      cobuyer_previous_county: info?.data?.previous_county,
      has_same_address_as_cobuyer: true,
    };

    return {
      ...resetedCobuyerAddressData,
      ...buyerAddressData,
      ...resetedCobuyerResidenceData,
      ...buyerResidenceData,
    };
  };

  const onSubmitForm = async (values: CobuyerResidenceValues) => {
    updateState({ status: 'loading' });

    const cobuyerAddressData = {
      cobuyer_address_line: values?.cobuyer_address_line,
      cobuyer_address_line_2: values?.cobuyer_address_line_2,
      cobuyer_city: values?.cobuyer_city?.trim(),
      cobuyer_state: values?.cobuyer_state,
      cobuyer_county: values?.cobuyer_county?.trim(),
      cobuyer_zip: values?.cobuyer_zip?.toString(),
    };

    const cobuyerResidenceData = {
      cobuyer_primary_residence_type: values?.cobuyer_primary_residence_type,
      cobuyer_primary_residence_years: values?.cobuyer_primary_residence_years,
      cobuyer_primary_residence_months: values?.cobuyer_primary_residence_months ?? 0,
      cobuyer_primary_residence_monthly_payment:
        values?.cobuyer_primary_residence_type !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT
          ? values?.cobuyer_primary_residence_monthly_payment ?? undefined
          : undefined,
      cobuyer_secondary_residence_years: values?.cobuyer_secondary_residence_years,
      cobuyer_secondary_residence_months: values?.cobuyer_secondary_residence_months ?? 0,
      cobuyer_previous_address_line: values?.cobuyer_previous_address_line,
      cobuyer_previous_address_line_2: values?.cobuyer_previous_address_line_2,
      cobuyer_previous_city: values?.cobuyer_previous_city?.trim(),
      cobuyer_previous_state: values?.cobuyer_previous_state,
      cobuyer_previous_zip: values?.cobuyer_previous_zip
        ? values?.cobuyer_previous_zip.toString()
        : undefined,
      cobuyer_previous_county: values?.cobuyer_previous_county?.trim(),
    };

    const data = liveTogether
      ? sameAsBuyerData()
      : { ...cobuyerAddressData, ...cobuyerResidenceData };

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data,
        },
      },
    });

    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  return (
    <Box>
      <Center mb={{ base: 4, md: 0 }}>
        <TextWithTooltip label="Verifying residential information gives lenders insight into regular expenses and, if applicable, payment history.">
          Why do we need this?
        </TextWithTooltip>
      </Center>
      <Formik
        enableReinitialize
        initialValues={cobuyerResidenceInitialValues(tempInfoData)}
        validationSchema={cobuyerResidenceValidationSchema(liveTogether)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, errors, values, setFieldValue }) => {
          const hasErrors = Object.values(errors).some((value) => value !== '');

          return (
            <Form>
              <ButtonedRadioGroup
                value={liveTogether.toString()}
                my={6}
                columns={2}
                onChange={() => {
                  setLiveTogether(!liveTogether);
                  setShowMonthlyCost(liveTogether);
                }}
                options={[
                  {
                    label: `${tempInfoData?.cobuyer_first_name?.toUpperCase()} AND I LIVE TOGETHER`,
                    value: 'true',
                  },
                  {
                    label: `${tempInfoData?.cobuyer_first_name?.toUpperCase()} AND I LIVE SEPARATELY`,
                    value: 'false',
                  },
                ]}
                name="live_together"
                defaultValue="true"
              />
              {!liveTogether && (
                <>
                  <AddressInput label="ADDRESS" prefix="cobuyer" />
                  <Divider mt={8} mb={8} />
                  <Label mt={5}>LIVING SITUATION</Label>
                  <ButtonedRadioGroup
                    columns={{ base: 2, md: 4 }}
                    collapseOnMobile={false}
                    onChange={(value) => {
                      setFieldValue('cobuyer_primary_residence_type', value);
                      setShowMonthlyCost(value !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT);
                    }}
                    options={[
                      { label: 'PAYING MORTGAGE', value: OWNERSHIP_TYPE.CURRENTLY_HAVE_A_MORTGAGE },
                      { label: 'RENTING', value: OWNERSHIP_TYPE['RENTING/_LEASING'] },
                      { label: 'OWN OUTRIGHT', value: OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT },
                      { label: 'LIVING W/FAMILY', value: OWNERSHIP_TYPE.LIVING_WITH_FAMILY },
                    ]}
                    value={values?.cobuyer_primary_residence_type as string}
                    name="cobuyer_primary_residence_type"
                    defaultValue={OWNERSHIP_TYPE.CURRENTLY_HAVE_A_MORTGAGE}
                  />
                  <Box color="leaseEndRed">{errors.cobuyer_primary_residence_type}</Box>
                  <Box mt={8} hidden={!showMonthlyCost}>
                    <Label>MONTHLY PAYMENT</Label>
                    <NumberInput
                      name="cobuyer_primary_residence_monthly_payment"
                      type="decimal"
                      isMoney
                      maxW="200px"
                      maxLength={8}
                      _input={{ inputMode: 'numeric' }}
                      _container={{ alignItems: 'flex-start' }}
                    />
                  </Box>
                  <Divider mt={8} mb={8} />
                  <Label mb={3}>TIME AT ADDRESS?</Label>
                  <MonthsAndYears
                    setShow={setShowPreviousResidence}
                    yearsName="cobuyer_primary_residence_years"
                    monthsName="cobuyer_primary_residence_months"
                    values={values}
                    mb={showPreviousResidence ? 4 : 0}
                  />
                  <Collapse in={showPreviousResidence} animateOpacity>
                    <Box w="100%" mb={2} p="8px" borderRadius="xl" bgColor="beige">
                      {capitalizeFirstLetter(tempInfoData?.cobuyer_first_name)} has lived here for{' '}
                      <b>less than two years</b>. Where did they live before?{' '}
                      <TextWithTooltip
                        textProps={{
                          fontSize: { base: '10px', md: '12px' },
                          cursor: showPreviousResidence ? 'help' : 'default',
                        }}
                        label={
                          showPreviousResidence
                            ? "To assess risk (and payment history), lenders prefer looking at more than two years' worth of data."
                            : ''
                        }
                      >
                        (Why does this matter?)
                      </TextWithTooltip>
                    </Box>
                    <Box overflow="hidden" mb={8}>
                      <AddressInput
                        label="PREVIOUS ADDRESS"
                        prefix="cobuyer_previous"
                        autoCompleteDisabled
                      />
                      <Label mt={6} mb={3}>
                        TIME AT PREVIOUS ADDRESS?
                      </Label>
                      <MonthsAndYears
                        yearsName="cobuyer_secondary_residence_years"
                        monthsName="cobuyer_secondary_residence_months"
                      />
                    </Box>
                  </Collapse>
                </>
              )}
              <Center>
                <PrimaryButton
                  mt="30px"
                  mb={{
                    base: '20px',
                    lg: '0',
                  }}
                  loading={isSubmitting}
                  type="submit"
                />
              </Center>
              {hasErrors && (
                <Text color="leaseEndRed" textAlign="center" mt={{ base: 0, lg: 4 }}>
                  Review fields above for errors.
                </Text>
              )}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CobuyerResidence;
