import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

import { Maybe } from '../../gql/generated/graphql';
import { NumberInput } from '../formComponents';

export const shouldRequirePrevious = (years: Maybe<number>, months: number) =>
  years === 0 || (years === 1 && months < 8);

interface MonthsAndYearsProps extends FlexProps {
  yearsName: string;
  monthsName: string;
  setShow?: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
}

const MonthsAndYears = ({
  yearsName,
  monthsName,
  setShow,
  values,
  ...rest
}: MonthsAndYearsProps) => {
  return (
    <Flex alignItems="flex-start" {...rest}>
      <Flex mr={4} alignItems="baseline">
        <NumberInput
          name={yearsName}
          maxLength={3}
          isWhole
          _input={{ inputMode: 'numeric', autoComplete: 'off' }}
          placeholder="search term"
          _container={{ maxW: '60px' }}
          errorMessageWidth="200%"
          {...(setShow && {
            onBlur: () => setShow(shouldRequirePrevious(values[yearsName], values[monthsName])),
          })}
        />
        <Text color="taupeGray" ml={2} fontSize="12px">
          YEAR(S)
        </Text>
      </Flex>
      <Flex alignItems="baseline">
        <NumberInput
          name={monthsName}
          max={11}
          maxLength={2}
          isWhole
          _input={{ inputMode: 'numeric', autoComplete: 'off' }}
          placeholder="search term"
          _container={{ maxW: '60px' }}
          errorMessageWidth="200%"
          {...(setShow && {
            onBlur: () => setShow(shouldRequirePrevious(values[yearsName], values[monthsName])),
          })}
        />
        <Text color="taupeGray" ml={2} fontSize="12px">
          MONTH(S)
        </Text>
      </Flex>
    </Flex>
  );
};

export default MonthsAndYears;
