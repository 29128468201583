import { Button, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';

import { TemporaryInfo } from '../../../../../gql/generated/graphql';
import LetsFixThat from './LetsFixThat';

interface Props {
  info?: TemporaryInfo;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  copy?: string;
}

const SomethingsNotRight = ({ info, setRefresh, copy }: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Flex justifyContent="center">
        <Button variant="link" pl={1} cursor="pointer" onClick={() => setOpenModal(!openModal)}>
          {copy ?? "No, something's not right"}
        </Button>
      </Flex>
      <LetsFixThat
        info={info}
        isOpen={openModal}
        setOpenModal={setOpenModal}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default SomethingsNotRight;
