import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';

import { JumpingMan } from '../../../assets/Images';
import { CONTACT_US } from '../../../constants/urls';
import { DealType } from '../../../gql/generated/graphql';
import UploadDocuments from '../components/UploadDocuments';
import YourTeam from '../components/YourTeam';
import { DealWithFarthestState } from '../utils';

const PendingDashboard = ({ deal }: { deal?: DealWithFarthestState }) => {
  const isRefi = deal?.type === DealType.Refi;
  const isCobuyer = deal?.isCobuyer;

  return (
    <Container mt="20px">
      <Flex>
        <Image
          src={JumpingMan}
          alt="Jumping Man"
          maxWidth="250px"
          display={{ base: 'none', lg: 'block' }}
        />
        <Box mx="15px">
          <Text fontSize="36px" fontWeight="bold">
            Welcome to Your {isCobuyer ? 'Co-Buyer ' : ''}Dashboard
          </Text>
          <Text mt="10px">
            This is where you'll find updates on your {isRefi ? 'auto refinance' : 'lease payoff'}{' '}
            status as you move forward with our services. We'll keep you informed every step of the
            way.
            <br /> <br />
            If you have any questions or concerns, please don't hesitate to{' '}
            <Link href={CONTACT_US} isExternal>
              reach out to us
            </Link>
            . Our team is here to help you navigate this process.
            <br /> <br />
            Thank you for choosing Lease End — we look forward to working with you!
          </Text>
          <YourTeam textAlign={{ base: 'center', lg: 'left' }} isBooted />
        </Box>
      </Flex>
      <Box maxW="320px" mx="auto">
        <UploadDocuments deal={deal} title="UPLOAD DOCUMENTS" />
      </Box>
    </Container>
  );
};

export default PendingDashboard;
