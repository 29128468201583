import { createContext, useEffect, useState } from 'react';

import {
  DealMedia,
  DealMediaTypeEnum,
  useCreateExternalDealUploadUrlMutation,
  useMediaDeleteFromComMutation,
  useMediaFromComLazyQuery,
  useMediaInsertComMutation,
  useMediaUpdateComMutation,
} from '../gql/generated/graphql';
import { NEW_DEAL_VALUE } from '../pages/dashboard/Dashboard';
import { logger } from '../services/sentry';
import { convertFile, generateFileName, getFilePathFromSignedUrl } from '../utils/media';

interface Props {
  dealId: string | number | undefined;
}

const useMediaCollection = ({ dealId }: Props) => {
  // queries and mutations
  const [createUploadUrlCom] = useCreateExternalDealUploadUrlMutation();
  const [mediaInsert] = useMediaInsertComMutation();
  const [updateImage] = useMediaUpdateComMutation();
  const [deleteMedia] = useMediaDeleteFromComMutation();

  // state
  const [media, setMedia] = useState<DealMedia[]>([]);
  const [customerUploadedMedia, setCustomerUploadedMedia] = useState<DealMedia[]>([]);

  // This is done with lazyQuery and useEffect so the query re-runs when changing deal in dashboard
  const [fetchMediaFromCom] = useMediaFromComLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      dealId: dealId === NEW_DEAL_VALUE.id ? undefined : dealId,
    },
    onCompleted: (data) => {
      if (data?.mediaFromCom) {
        const allMedia = data.mediaFromCom;
        setMedia(allMedia);
        setCustomerUploadedMedia(allMedia.filter((m) => m.uploaded_by_customer));
      }
    },
  });

  useEffect(() => {
    fetchMediaFromCom();
  }, [dealId]);

  // util functions
  const updateMedia = (newMedia: DealMedia) => {
    setMedia((prev) => prev.map((picture) => (picture.id === newMedia.id ? newMedia : picture)));
  };

  const deleteImageForKey = (mediaType: DealMediaTypeEnum) => {
    return new Promise((resolve) => {
      setMedia((prev) => {
        const image = prev.find((doc) => doc.type === mediaType);
        if (!image?.signed_url) {
          resolve(null);
          return prev;
        }
        deleteMedia({
          variables: {
            dealId: image.deal_id || '',
            filePath: getFilePathFromSignedUrl(image.signed_url),
          },
        });
        const newMedia = prev.filter((picture) => image.id !== picture.id);
        resolve(null);
        return newMedia;
      });
    });
  };

  // general functions
  const uploadImage = async ({
    mediaType,
    dealId: dealIdToUpload,
    file,
    deletePrev = true,
    allowPdf,
  }: {
    mediaType: DealMediaTypeEnum;
    dealId: string | number;
    file?: File;
    deletePrev?: boolean;
    allowPdf?: boolean;
  }) => {
    const convertedFile = await convertFile(file, allowPdf);

    const filename = generateFileName(mediaType, convertedFile.name);

    try {
      const { data } = await createUploadUrlCom({
        variables: {
          fileName: filename,
          fileDescription: mediaType,
          dealId: dealIdToUpload,
        },
      });

      if (data?.createExternalDealUploadUrl?.url && data?.createExternalDealUploadUrl?.key) {
        if (deletePrev) {
          await deleteImageForKey(mediaType);
        }

        const uploadResponse = await fetch(data?.createExternalDealUploadUrl?.url, {
          method: 'PUT',
          body: convertedFile,
        });

        if (!uploadResponse.ok) {
          throw Error();
        }

        const { data: mediaInsertData } = await mediaInsert({
          variables: {
            key: data?.createExternalDealUploadUrl?.key,
            type: mediaType,
            dealId: dealIdToUpload,
          },
        });

        const newMedia = mediaInsertData?.mediaInsertCom;
        if (!newMedia) {
          throw Error();
        }

        setMedia((prev) => [...prev, newMedia as DealMedia]);
        setCustomerUploadedMedia((prev) => [...prev, newMedia as DealMedia]);
      }
    } catch (error) {
      logger.error(
        'useMediaCollection.tsx',
        'There was an error uploading your file.',
        mediaType,
        error,
      );
      throw Error('There was an error uploading your file.');
    }
  };

  const deleteImage = async (mediaToDelete: DealMedia) => {
    try {
      if (!mediaToDelete.signed_url) {
        throw Error();
      }
      await deleteMedia({
        variables: {
          dealId: mediaToDelete.deal_id || '',
          filePath: getFilePathFromSignedUrl(mediaToDelete.signed_url),
        },
      });
      setMedia((prev) => prev.filter((image) => image.id !== mediaToDelete.id));
    } catch (error) {
      logger.error(
        'useMediaCollection.tsx',
        'There was an error deleting your image.',
        mediaToDelete,
        error,
      );
      throw Error('There was an error deleting your image.');
    }
  };

  // This will also delete a previous image if it exists
  const setImageType = async (oldKey: string, mediaType?: DealMediaTypeEnum) => {
    try {
      if (mediaType) {
        await deleteImageForKey(mediaType);
      }

      let newFileName: string | null = null;
      if (mediaType) {
        newFileName = generateFileName(mediaType, oldKey);
      }
      const newMedia = await updateImage({
        variables: {
          dealId: media[0].deal_id || '',
          oldKey,
          newFileName,
          type: mediaType,
        },
      });
      if (newMedia.data?.mediaUpdateCom) {
        updateMedia(newMedia.data.mediaUpdateCom);
      }
    } catch (error) {
      logger.error(
        'useMediaCollection.tsx',
        'There was an error setting your image.',
        oldKey,
        error,
      );
      throw Error('There was an error setting your image.');
    }
  };

  return {
    media,
    uploadImage,
    deleteImage,
    setImageType,
    customerUploadedMedia,
  };
};

export type MediaContextType = ReturnType<typeof useMediaCollection>;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunction = async () => {};

export const MediaContext = createContext<ReturnType<typeof useMediaCollection>>({
  media: [],
  customerUploadedMedia: [],
  uploadImage: emptyFunction,
  deleteImage: emptyFunction,
  setImageType: emptyFunction,
});

export default useMediaCollection;
