import { Box, Center, Flex, Spinner, VStack } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Maybe } from 'yup/lib/types';

import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import TextWithTooltip from '../../../../components/TextWithTooltip';
import { Input, NumberInput, Select } from '../../../../components/formComponents';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { VEHICLE_COLORS } from '../../../../constants/vehicleColors';
import {
  KbbVinResult,
  useCalculateOptionsLazyQuery,
  useKbbValuesLazyQuery,
  useKbbVinQuery,
  useTemporaryInfoUpdateMutation,
} from '../../../../gql/generated/graphql';
import { useTempInfo } from '../../../../hooks';
import {
  endingInZeroMileageWarningApplicable,
  overMileageWarningApplicable,
} from '../../../../utils/mileageWarnings';
import { notRequiredString } from '../../../../utils/strings';
import { customerAddressValidation } from '../../../../utils/validation/customerInformation';
import { mileageValidation } from '../../../../utils/validation/mileage';
import ColorPicker from '../JustTheBasicsItem/components/ColorPicker';
import MileageWarning from '../JustTheBasicsItem/components/MileageWarning';
import { useZip } from '../JustTheBasicsItem/useZip';
import SomethingsNotRight from '../LooksRightItem/components/SomethingsNotRight';
import VehicleInfo from '../LooksRightItem/components/VehicleInfo';

const TRIM_EXPLANATION = `
  Trim is basically a fancy term for the different features and options that come with a specific
  model of car. This can include everything from the engine and transmission to exterior and
  interior upgrades. The trim is usually located on the inside of the driver's side door or in
  the owner's manual.
`;

interface FormikValues {
  vehicleTrim: string;
  mileage: string;
  zip: string;
  city: string;
  county: string;
  state: string;
  color: VEHICLE_COLORS;
}

const VehicleDetails = ({ refreshToggle }: { refreshToggle: () => void }) => {
  const { updateState, index, setOpenAccordionIndex } = useLEAccordionItemContext();
  const [refresh, setRefresh] = useState(false);
  const [showingErrors, setShowingErrors] = useState<boolean>(false);
  const [inputMileage, setInputMileage] = useState<number>(0);
  const [mileageWarnings, setMileageWarnings] = useState<{
    endingInZero: boolean;
    overMileage: boolean;
  }>({
    endingInZero: false,
    overMileage: false,
  });
  const { endingInZero, overMileage } = mileageWarnings;

  const [calculateOptions] = useCalculateOptionsLazyQuery({ fetchPolicy: 'network-only' });
  const [getKbbValues] = useKbbValuesLazyQuery();
  const [upsertTemporaryInfo] = useTemporaryInfoUpdateMutation();

  const { info, infoLoading } = useTempInfo(undefined, refresh);
  const { isCityCountyNeeded, handleZip, CityCounty } = useZip<FormikValues>();
  const { data: vinData, loading: kbbLoading } = useKbbVinQuery({
    variables: { vin: info?.data?.vin as string },
    skip: !info?.data?.vin,
  });
  const options = Array.from(
    new Set(
      vinData?.kbbVin?.vinResults?.map((vinResult) => ({
        label: vinResult?.trimName || '',
        value: vinResult?.trimName || '',
      })),
    ),
  );

  const handleNext = async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    if (!info?.data) {
      return;
    }
    updateState({ status: 'loading' });

    const vehicle: Maybe<KbbVinResult> = vinData?.kbbVin?.vinResults?.find(
      (vinResult) => vinResult?.trimName === values.vehicleTrim,
    );

    const mileage = Number(values.mileage);
    if ((endingInZero || overMileage) && !showingErrors) {
      setShowingErrors(true);
      updateState({ status: 'warn' });
      return;
    }

    const response = await calculateOptions({
      variables: {
        vehicleId: vehicle?.vehicleId,
        allKbbVehicleOptions: vehicle?.vehicleOptions,
        vin: info.data.vin ?? '',
        color: values.color,
      },
    });

    const selectedOptions = response?.data?.calculateOptions ?? [];
    const selectedOptionIds = selectedOptions.map((option) => Number(option?.vehicleOptionId));

    const { data: valuesData } = await getKbbValues({
      variables: {
        data: {
          mileage,
          allKbbVehicleOptions: vehicle?.vehicleOptions,
          vehicleId: vehicle?.vehicleId,
          vehicleOptionIds: selectedOptionIds,
        },
      },
    });

    const zipValues = await handleZip(values, helpers);
    if (!zipValues) {
      updateState({ status: 'warn' });
      return;
    }
    const { city, county, state, zip } = zipValues;

    const data = {
      kbb_vehicle_id: vehicle?.vehicleId,
      kbb_vehicle_name: vehicle?.vehicleName,
      kbb_trim_name: vehicle?.trimName,
      kbb_all_options: vehicle?.vehicleOptions,
      retail_book_value: valuesData?.kbbValues?.retail,
      kbb_retail_mileage_adjustment: valuesData?.kbbValues?.retailMileageAdjustment,
      kbb_retail_option_adjustment: valuesData?.kbbValues?.retailOptionAdjustment,
      book_value: valuesData?.kbbValues?.lending,
      kbb_lending_mileage_adjustment: valuesData?.kbbValues?.lendingMileageAdjustment,
      kbb_lending_option_adjustment: valuesData?.kbbValues?.lendingOptionAdjustment,
      kbb_valuation_date: valuesData?.kbbValues?.valuationDate,
      kbb_selected_options: valuesData?.kbbValues?.kbbSelectedOptions,
      zip,
      city: city.trim(),
      county: county.trim(),
      state,
      mileage: Number(values.mileage),
      color: values.color,
    };

    refreshToggle();

    await upsertTemporaryInfo({
      variables: {
        info: {
          id: info.id,
          data,
        },
      },
    });

    // DO YOUR BUSINESS

    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  useEffect(() => {
    if (!inputMileage) return;

    setMileageWarnings({
      endingInZero: endingInZeroMileageWarningApplicable(inputMileage),
      overMileage: overMileageWarningApplicable(inputMileage),
    });
  }, [inputMileage]);

  return (
    <Box color="leaseendBlue" mt={2}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleNext}
        initialValues={{
          vehicleTrim: options.length === 1 ? options[0].label : info?.data.kbb_trim_name ?? '',
          mileage: String(info?.data.mileage || ''),
          zip: info?.data.zip || '',
          city: info?.data.city || '',
          county: info?.data.county || '',
          state: info?.data.state || '',
          color: (info?.data.color || '') as VEHICLE_COLORS,
        }}
        validationSchema={Yup.object({
          vehicleTrim:
            options.length || info?.data.kbb_trim_name
              ? Yup.string().required('Please select a trim')
              : notRequiredString,
          zip: customerAddressValidation.zip,
          mileage: mileageValidation,
          color: Yup.string().required('Color is required'),
          ...(isCityCountyNeeded
            ? {
                city: customerAddressValidation.city,
                county: customerAddressValidation.county,
                state: customerAddressValidation.state,
              }
            : {}),
        })}
      >
        {({ isSubmitting, values }) => {
          const mileage = Number(values.mileage);
          return (
            <Form>
              <VStack>
                <VehicleInfo
                  year={info?.data.year ?? ''}
                  make={info?.data.make ?? ''}
                  model={info?.data.model ?? ''}
                  vin={info?.data.vin ?? ''}
                  infoLoading={infoLoading}
                />
                <SomethingsNotRight info={info} setRefresh={setRefresh} copy="This isn't my car" />
              </VStack>
              {kbbLoading ? (
                <Box mt="24px !important">
                  <Spinner />
                </Box>
              ) : (
                options.length > 1 && (
                  <>
                    <Select
                      display={options.length > 0 ? 'block' : 'none'}
                      name="vehicleTrim"
                      placeholder="Select Trim"
                      alignSelf="self-start"
                      w="full"
                      mt={5}
                      mb={2}
                      options={options}
                      menuListHeight="100px"
                    />
                    <TextWithTooltip label={TRIM_EXPLANATION}>
                      How do I know what my trim is?
                    </TextWithTooltip>
                  </>
                )
              )}
              <NumberInput
                mt="15px"
                name="mileage"
                placeholder="Mileage"
                label="Current Odometer"
                showThousandSeparator
                isWhole
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInputMileage(Number(event.target.value.replace(',', '')));
                  setShowingErrors(false);
                }}
              />
              <Flex flexDir="row" my="15px" gap="3%" justifyContent="center">
                <Input
                  w="100%"
                  name="zip"
                  placeholder="ZIP Code"
                  label="Zip Code"
                  alignSelf="flex-start"
                  _input={{
                    inputMode: 'numeric',
                    maxLength: 5,
                  }}
                />
                <ColorPicker />
              </Flex>
              {showingErrors && (
                <MileageWarning mileage={mileage} mileageWarnings={mileageWarnings} />
              )}
              {CityCounty}
              <Center mt="30px">
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                  NEXT
                </PrimaryButton>
              </Center>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default VehicleDetails;
