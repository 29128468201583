import * as Yup from 'yup';

import { shouldRequirePrevious } from '../../components/monthsAndYears/MonthsAndYears';
import { OWNERSHIP_TYPE } from '../../constants/addresses';
import { EmploymentStatusType } from '../../gql/generated/graphql';
import { notRequiredString } from '../strings';
import {
  addressLine2Validation,
  addressLineValidation,
  cityValidation,
  countyValidation,
  stateValidation,
} from './address';
import { emailValidationRequired } from './email';
import {
  employerNameValidation,
  employmentStatusValidation,
  jobTitleValidation,
} from './employment';
import { nameValidationMax30 } from './names';
import {
  monthsValidation,
  numberOptionalValidation,
  numberRequiredValidation,
  salaryRequiredAndMaxValidation,
} from './numbers';
import { phoneValidationRequired } from './phoneNumber';
import { residenceTypeValidation, residenceYearsValidation } from './residence';
import { zipValidation } from './zipCodes';

// Personal Info
export const customerNameValidation = {
  first_name: nameValidationMax30,
  last_name: nameValidationMax30,
};

export const customerContactInfoValidation = {
  email: emailValidationRequired,
  phone_number: phoneValidationRequired,
};
export const customerPersonalInfoValidation = {
  ...customerNameValidation,
  ...customerContactInfoValidation,
};

// Address
export const customerAddressValidation = {
  address_line: addressLineValidation,
  address_line_2: addressLine2Validation,
  city: cityValidation,
  county: countyValidation,
  state: stateValidation,
  zip: zipValidation,
};

export const customerPreviousAddressValidation = {
  previous_address_line: addressLineValidation,
  previous_address_line_2: addressLine2Validation,
  previous_city: cityValidation,
  previous_state: stateValidation,
  previous_county: countyValidation,
  previous_zip: zipValidation,
};

// Residence
export const customerPrimaryResidenceValidation = {
  primary_residence_type: residenceTypeValidation,
  primary_residence_years: residenceYearsValidation,
  primary_residence_months: Yup.number().when('primary_residence_years', {
    is: (primary_residence_years: number) => primary_residence_years <= 1,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
  primary_residence_monthly_payment: Yup.number().when('primary_residence_type', {
    is: (primary_residence_type: string) =>
      primary_residence_type !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT,
    then: numberRequiredValidation,
    otherwise: numberOptionalValidation,
  }),
};

export const customerPreviousResidenceValidation = {
  secondary_residence_years: Yup.number().when(
    ['primary_residence_years', 'primary_residence_months'],
    {
      is: shouldRequirePrevious,
      then: residenceYearsValidation,
      otherwise: numberOptionalValidation,
    },
  ),
  secondary_residence_months: Yup.number().when('secondary_residence_years', {
    is: (secondary_residence_years: number) => secondary_residence_years === 0,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
  previous_address_line: Yup.string().when(
    ['primary_residence_years', 'primary_residence_months'],
    {
      is: shouldRequirePrevious,
      then: addressLineValidation,
      otherwise: notRequiredString,
    },
  ),
  previous_address_line_2: Yup.string().when(
    ['primary_residence_years', 'primary_residence_months'],
    {
      is: shouldRequirePrevious,
      then: addressLine2Validation,
      otherwise: notRequiredString,
    },
  ),
  previous_city: Yup.string().when(['primary_residence_years', 'primary_residence_months'], {
    is: shouldRequirePrevious,
    then: cityValidation,
    otherwise: notRequiredString,
  }),
  previous_state: Yup.string().when(['primary_residence_years', 'primary_residence_months'], {
    is: shouldRequirePrevious,
    then: stateValidation,
    otherwise: notRequiredString,
  }),
  previous_zip: Yup.string().when(['primary_residence_years', 'primary_residence_months'], {
    is: shouldRequirePrevious,
    then: zipValidation,
    otherwise: notRequiredString,
  }),
  previous_county: Yup.string().when(['primary_residence_years', 'primary_residence_months'], {
    is: shouldRequirePrevious,
    then: countyValidation,
    otherwise: notRequiredString,
  }),
};

// Employment
export const customerEmploymentValidation = {
  employment_status: employmentStatusValidation,
  employer_name: Yup.string().when('employment_status', {
    is: (employment_status: EmploymentStatusType) =>
      employment_status === EmploymentStatusType.Employed,
    then: employerNameValidation,
    otherwise: notRequiredString,
  }),
  employer_phone_number: Yup.string().when('employment_status', {
    is: (employment_status: EmploymentStatusType) =>
      employment_status === EmploymentStatusType.Employed,
    then: phoneValidationRequired,
    otherwise: notRequiredString,
  }),
  job_title: Yup.string().when('employment_status', {
    is: (employment_status: EmploymentStatusType) =>
      employment_status === EmploymentStatusType.Employed,
    then: jobTitleValidation,
    otherwise: notRequiredString,
  }),
  years_at_job: numberRequiredValidation,
  months_at_job: Yup.number().when('years_at_job', {
    is: (years_at_job: number) => years_at_job <= 1,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
  salary: salaryRequiredAndMaxValidation,
};

export const customerPreviousEmploymentValidation = {
  previous_employer_name: Yup.string().when(['years_at_job', 'months_at_job'], {
    is: shouldRequirePrevious,
    then: employerNameValidation,
    otherwise: notRequiredString,
  }),
  previous_employer_phone_number: Yup.string().when(['years_at_job', 'months_at_job'], {
    is: shouldRequirePrevious,
    then: phoneValidationRequired,
    otherwise: notRequiredString,
  }),
  previous_job_title: Yup.string().when(['years_at_job', 'months_at_job'], {
    is: shouldRequirePrevious,
    then: jobTitleValidation,
    otherwise: notRequiredString,
  }),
  previous_years_at_job: Yup.number().when(['years_at_job', 'months_at_job'], {
    is: shouldRequirePrevious,
    then: numberRequiredValidation,
    otherwise: numberOptionalValidation,
  }),
  previous_months_at_job: Yup.number().when('previous_years_at_job', {
    is: (previous_years_at_job: number) => previous_years_at_job === 0,
    then: monthsValidation,
    otherwise: numberOptionalValidation,
  }),
};
