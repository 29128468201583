import { Box, Card, Center, Container, Link, Text } from '@chakra-ui/react';

import CarDetails from '../../../components/CarDetails';
import GetStarted from '../../../components/ui/GetStarted/GetStarted';
import { Car, Maybe } from '../../../gql/generated/graphql';
import getSearchPhoneNumbers from '../../../utils/getSearchPhoneNumbers';

interface Props {
  inFlow: boolean;
  car: Maybe<Car>;
}

const NewDealDashboard = ({ inFlow, car }: Props) => {
  const phoneNumber = getSearchPhoneNumbers();

  return (
    <Container mt="20px">
      <Box textAlign="center">
        <Text fontSize="36px" fontWeight="bold">
          The easiest way to end your lease
        </Text>
        {!inFlow && (
          <Text mt="10px">
            If you've already been working with us and expect to see your car details here but
            can't, there may be an issue on our end. Please contact us at{' '}
            <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link>. We may have saved an
            incorrect email.
          </Text>
        )}
      </Box>
      <Center my="30px" mt={inFlow ? '50px' : '30px'}>
        <GetStarted />
      </Center>
      {inFlow && car && (
        <Card overflow="hidden" mx="auto" maxW={{ base: '300px', md: '350px' }}>
          <CarDetails car={car} />
        </Card>
      )}
    </Container>
  );
};

export default NewDealDashboard;
