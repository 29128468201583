import { CardProps, Flex, Text } from '@chakra-ui/react';
import { FaMoneyCheckDollar } from 'react-icons/fa6';

import CarDetails from '../../../components/CarDetails';
import { DealStateEnum } from '../../../utils/types/deal';
import { DOWN_PAYMENT_STATES, DealWithFarthestState } from '../utils';
import DashboardCard from './DashboardCard';
import PaymentInfo from './PaymentInfo';

interface Props {
  deal?: DealWithFarthestState;
  _cardProps?: CardProps;
}

const DashboardDealSummaryCard = ({ deal, _cardProps }: Props) => {
  const showDownPaymentButton =
    (deal?.financial_info?.money_down ?? 0) > 0 &&
    DOWN_PAYMENT_STATES.includes(deal?.state as DealStateEnum);
  const isCobuyer = deal?.isCobuyer;

  return (
    <DashboardCard
      title={`Deal Summary${isCobuyer ? ' (Co-Buyer)' : ''}`}
      icon={FaMoneyCheckDollar}
      {..._cardProps}
    >
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        gap="20px"
        h={!deal ? '100%' : undefined}
        minH="180px"
      >
        <CarDetails
          car={deal?.car}
          title={`MY VEHICLE${deal?.isCobuyer ? ' (CO-BUYER)' : ''}`}
          w={{ md: 1 / 3 }}
          borderRadius="10px"
          justifyContent="center"
          bgColor="grayBackground"
        />
        <PaymentInfo deal={deal} showDownPaymentButton={showDownPaymentButton} w={{ md: 2 / 3 }} />
      </Flex>
      {showDownPaymentButton && (
        <Text textAlign="end" color="gray" fontSize="12px" mt={2}>
          * Payments already made may not be reflected here
        </Text>
      )}
    </DashboardCard>
  );
};

export default DashboardDealSummaryCard;
