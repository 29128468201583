import { Link, Text, ToastProps, UseToastOptions } from '@chakra-ui/react';
import React from 'react';

import getSearchPhoneNumbers from './getSearchPhoneNumbers';
import { RudderEvent, rudderanalytics } from './rudderstack';

export const TOAST_DURATION = 30000;

export interface ToastOpts {
  errorMessage?: string;
  title?: ToastProps['title'];
  description?: React.ReactNode;
}

export const createErrorToast = ({ errorMessage, title, description }: ToastOpts) => {
  const phoneNumber = getSearchPhoneNumbers();
  let errorToShow = errorMessage || '';
  if (errorToShow?.length > 60) {
    errorToShow = `${errorToShow?.slice(0, 60)}...`;
  }

  const toastOptions: UseToastOptions = {
    title: title || 'Something seems to have gone wrong on our end.',
    description: description || (
      <>
        {errorToShow.length > 0 && <Text>Error: {errorToShow}</Text>}
        <Text>
          Please refresh the page. If the issue persists, call us at{' '}
          <Link
            _hover={{ color: 'inherit', textDecoration: 'underline' }}
            href={`tel:+1-${phoneNumber}`}
            color="inherit"
          >
            {phoneNumber}
          </Link>{' '}
          to speak with one of our representatives.
        </Text>
      </>
    ),
    position: 'top-right',
    status: 'error',
    duration: TOAST_DURATION,
    isClosable: true,
    onCloseComplete: () => {
      if (!errorMessage) {
        return;
      }

      const { pathname } = window.location;

      rudderanalytics.track(RudderEvent.Error, {
        error_message: errorMessage,
        pathname,
      });
    },
  };
  return toastOptions;
};

export const createSuccessToast = ({
  title = 'Success!',
  description = '',
}: Omit<ToastOpts, 'errorMessage'>) => {
  const toastOptions: UseToastOptions = {
    title,
    description,
    position: 'top-right',
    status: 'success',
    duration: TOAST_DURATION,
    isClosable: true,
  };
  return toastOptions;
};
