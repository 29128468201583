import { Card, Center, Container } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { SSNInput } from '../../components/formComponents';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';

const validationSchema = Yup.object({
  ssn: Yup.string().required('SSN is required'),
});

const initialValues = {
  ssn: '',
};

const SecureDataEntry = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <Container textAlign="center">
        <TitleContainer>
          <Title>Submission Received</Title>
        </TitleContainer>
        <Subtitle>Your information has been securely recorded.</Subtitle>
      </Container>
    );
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <Form>
          <Container textAlign="center">
            <TitleContainer>
              <Title>Secure Data Entry</Title>
            </TitleContainer>
            <Subtitle>A safe and secure way to input your information.</Subtitle>
            <Card borderRadius="15px" p={{ base: '15px', md: '30px' }}>
              <SSNInput name="ssn" digits="9" />
              <Center mt={4}>
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                  SUBMIT
                </PrimaryButton>
              </Center>
            </Card>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default SecureDataEntry;
