import { Flex, Text } from '@chakra-ui/react';
import { Form, Formik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';

import CarDetails from '../../../components/CarDetails';
import Modal from '../../../components/Modal';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { SSNInput } from '../../../components/formComponents';
import Button from '../../../components/ui/buttons/Button';
import { Deal, DealType, useVerifyAccountMutation } from '../../../gql/generated/graphql';
import { DealWithFarthestState } from '../../dashboard/utils';
import { getVerificationError } from '../utils';

interface Props {
  deal: DealWithFarthestState | Deal | undefined;
  open: boolean;
  onClose: () => void;
  handleNextSubmit: () => void;
  download?: boolean;
}

const SSN_DIGITS = '4';

const VerifyAccountModal = ({ deal, open, onClose, handleNextSubmit, download = false }: Props) => {
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setError('');
  }, [deal]);

  const [verifyAccountMutation] = useVerifyAccountMutation();

  const isRefi = deal?.type === DealType.Refi;

  const handleSubmit = async (values: FormikValues) => {
    try {
      await verifyAccountMutation({
        variables: {
          ssn_last_4: values.ssn.slice(-4),
          deal_id: deal?.id ?? '',
          is_cobuyer: deal?.isCobuyer,
        },
      }).then(() => {
        handleNextSubmit();
        onClose();
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      getVerificationError(e.message, setError);
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      size="md"
      subtitle={`Confirm your SSN to ${download ? 'access' : 'sign'} your documents.`}
    >
      <Flex flexDir="column" textAlign="center">
        <Formik
          enableReinitialize
          initialValues={{
            ssn: '',
            optOut: false,
            id: deal?.id, // This resets formik when changing between cars
            isCobuyer: deal?.isCobuyer, // This resets formik when changing between cars (where cobuyer and buyer have the same email)
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            {!download && (
              <>
                <CarDetails car={deal?.car} pb={0} />
                <Text textAlign="center" pt="10.5px">
                  You are a {deal?.isCobuyer && 'co'}buyer on this vehicle
                </Text>
                <TextWithTooltip label="If this is not your vehicle or if you are experiencing difficulty verifying the last four digits of your SSN, please reach out to us.">
                  {deal?.isCobuyer
                    ? `Not part of this ${isRefi ? 'loan' : 'lease'}?`
                    : 'Not your vehicle?'}
                </TextWithTooltip>
              </>
            )}
            <SSNInput
              mt="8px"
              label={`Last ${SSN_DIGITS} of ${deal?.isCobuyer ? 'cobuyer' : 'buyer'} SSN`}
              onChange={error ? () => setError('') : undefined}
              digits={SSN_DIGITS}
              name="ssn"
              _container={{ h: 'auto' }}
            />
            {error && (
              <Text color="red" fontSize="sm" mt={1} maxH="80px">
                {error}
              </Text>
            )}
            <Button mt="20px" mb="5px" w="full" type="submit">
              {download ? 'DOWNLOAD' : 'CONFIRM'}
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Modal>
  );
};

export default VerifyAccountModal;
