import { Box, Flex, FlexProps, Text, Textarea } from '@chakra-ui/react';
import { useField } from 'formik';

interface Props extends FlexProps {
  name: string;
  placeholder?: string;
  max?: number;
  size?: 'sm' | 'md' | 'lg';
  fontSize?: string;
  title?: string;
}

const TextBox = ({
  name,
  placeholder,
  max = 300,
  size = 'lg',
  fontSize = '16px',
  title,
  ...props
}: Props) => {
  const [field, meta, { setValue }] = useField<string>(name);
  const { value } = field;
  const isInvalid = meta.touched && meta.error != null && !field.value;

  return (
    <Flex flexDir="column" gap="8px" {...props}>
      {title && <Text>{title}</Text>}
      <Textarea
        size={size}
        resize="vertical"
        borderColor="grayDarkBackground"
        name={name}
        value={value}
        placeholder={placeholder}
        maxLength={max}
        fontSize={fontSize}
        _focus={{
          color: 'oceanBoatBlue',
        }}
        onChange={(e) => setValue(e.target.value)}
      />
      {isInvalid && (
        <Box color="leaseEndRed" fontSize="12.25px" ml="5px">
          {meta.error}
        </Box>
      )}
    </Flex>
  );
};

export default TextBox;
