import { Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { ReactNode } from 'react';

import TextWithTooltip from '../../components/TextWithTooltip';
import { TITLES_EMAIL } from '../../constants/contact';
import { GOOGLE } from '../../constants/urls';
import { Deal, DealType, Maybe } from '../../gql/generated/graphql';
import { getUTCDate } from '../../utils/dates';
import getSearchPhoneNumbers from '../../utils/getSearchPhoneNumbers';
import { DealStateEnum } from '../../utils/types/deal';
import { DocumentType } from '../documents/components/ThingsWeNeed/utils';
import SentForSignatures from './components/dealStatusDescriptions/SentForSignatures';

export const PRE_SIGNED_DEAL_STATES = [
  DealStateEnum.Estimate,
  DealStateEnum.Floor,
  DealStateEnum.SoftClose,
  DealStateEnum.StructuringInProgress,
  DealStateEnum.Structuring,
  DealStateEnum.Closing,
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
];

export const DEAL_TERMS_STATES = [
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
  DealStateEnum.SendPayoff,
  DealStateEnum.WaitingForTitle,
  DealStateEnum.TitleReceived,
  DealStateEnum.SentToProcessor,
  DealStateEnum.Finalized,
];

export const DOWN_PAYMENT_STATES = [
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
];

export const IGNORED_DEAL_STATES = [DealStateEnum.Booted];

export const RESET_DEAL_STATES = [
  DealStateEnum.Estimate,
  DealStateEnum.Floor,
  DealStateEnum.SoftClose,
  DealStateEnum.StructuringInProgress,
  DealStateEnum.Structuring,
  DealStateEnum.Closing,
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
];

export const DealStateMap: { [key in DealStateEnum]: number } = {
  [DealStateEnum.Estimate]: 0,
  [DealStateEnum.Floor]: 0,
  [DealStateEnum.SoftClose]: 0,
  [DealStateEnum.StructuringInProgress]: 0,
  [DealStateEnum.Structuring]: 0,

  [DealStateEnum.Closing]: 1,

  [DealStateEnum.Closed]: 2,
  [DealStateEnum.SentForSignatures]: 2,

  [DealStateEnum.Signed]: 3,

  [DealStateEnum.Funded]: 4,
  [DealStateEnum.SendPayoff]: 4,

  [DealStateEnum.WaitingForTitle]: 5,

  [DealStateEnum.TitleReceived]: 6,

  [DealStateEnum.SentToProcessor]: 7,
  // end acquisition steps
  [DealStateEnum.Booted]: 8,

  [DealStateEnum.Finalized]: 9,

  // acquisition steps
  [DealStateEnum.ReadyForPickup]: 0,
  [DealStateEnum.PaidOff]: 0,
  [DealStateEnum.AtAuction]: 0,
  [DealStateEnum.Sold]: 0,
  [DealStateEnum.TitleSent]: 0,
};

export const DealStateMapReverse: { [key: number]: DealStateEnum } = {
  0: DealStateEnum.Structuring,
  1: DealStateEnum.Closing,
  2: DealStateEnum.SentForSignatures,
  3: DealStateEnum.Signed,
  4: DealStateEnum.SendPayoff,
  5: DealStateEnum.WaitingForTitle,
  6: DealStateEnum.TitleReceived,
  7: DealStateEnum.SentToProcessor,
  8: DealStateEnum.Booted,
  9: DealStateEnum.Finalized,
};

export interface DealWithFarthestState extends Deal {
  farthestState: DealStateEnum;
}

interface DealStatusDescriptionsParams {
  deal?: DealWithFarthestState;
  requiredDocs: Maybe<DocumentType[]>;
}

const dealStatusDescriptions = ({
  deal,
  requiredDocs,
}: DealStatusDescriptionsParams): {
  [key in DealStateEnum]?: ReactNode;
} => {
  const bank = deal?.financial_info?.bank;
  const isRefi = deal?.type === DealType.Refi;
  const lienholder = deal?.car?.payoff?.lienholder_name;
  const phoneNumber = getSearchPhoneNumbers();
  let firstPaymentDate = '';

  const fundedAndSendPayoffDescription = () => (
    <>
      Hey there - we have some fantastic news to share with you! We’re in the process of paying off
      your {isRefi ? 'loan with your original lender' : `lease with ${lienholder}`}, which means you
      can stop making payments to them (and your account will be closed). Woohoo!
      <br />
      <br />
      You'll be receiving a welcome packet from {bank} soon. When you get it, it's <i>essential</i>{' '}
      that you update your auto insurance and replace your old{' '}
      {isRefi ? 'lender' : `lienholder ${lienholder}`} with your new{' '}
      {isRefi ? 'refinancing lender' : 'bank'}, {bank}. This is super important, so don't forget!
      <br />
      <br />
      Please keep in mind that it may take a couple of weeks for everything to fully update on your
      online accounts. Don’t worry, though - we've got it under control and will keep you posted on
      any updates or further requirements.
      <br />
      <br />
      Thanks for choosing Lease End
      {isRefi ? '.' : ' - we\'re excited for you to drive off into the sunset with your "new" car!'}
    </>
  );

  const waitingForTitleDescription = () => {
    const firstParagraph =
      `We've got more good news for you! Your ${
        isRefi ? 'loan account' : 'Lease Account'
      } is officially closed with ${isRefi ? 'your previous lender' : `${lienholder}`}. ` +
      `As a heads up, this may not reflect immediately on your online account. ` +
      `Now, we're eagerly waiting on your vehicle title from ${
        isRefi ? 'them' : `${lienholder}`
      }. ` +
      `It's kinda like getting a pizza delivered - all good things come to those who wait.`;

    const secondParagraph =
      `If you've recently moved, or if any of your documents (driver's license, registration, etc) ` +
      `are set to expire or change in the next 90 days, please let us know as soon as possible.`;

    const thirdParagraph =
      `We're dedicated to regulatory compliance (A.K.A. red tape) - for your sake and ours. ` +
      `Some state requirements mean processing vehicle titles can sometimes take a while, ` +
      `even when every document is completed perfectly. ` +
      `Notifying us of any recent or upcoming changes to your required documentation ASAP gives ` +
      `us the best possible chance to get ahead of any unnecessary delays.`;
    const fourthParagraph =
      `If we need any additional information from you, we'll be sure to reach out. ` +
      `Thank you for being our partner in this process - we're excited to see everything through to the end! ` +
      `If you have any questions or concerns, please let us know right away.`;

    return (
      <>
        {firstParagraph}
        <br />
        <br />
        {secondParagraph}
        <br />
        <br />
        {!isRefi && (requiredDocs?.length ?? 0) > 0 && (
          <>
            To avoid delays, please ensure you have the following documents ready:
            <br />
            <br />
            <UnorderedList>
              {requiredDocs?.map((doc) => (
                <ListItem key={doc.title}>
                  <TextWithTooltip
                    label={
                      <>
                        {doc.whyWeAsk} {doc.howToGetIt}
                      </>
                    }
                  >
                    {doc.title}
                  </TextWithTooltip>
                </ListItem>
              ))}
            </UnorderedList>
            <br />
          </>
        )}
        {thirdParagraph}
        <br />
        <br />
        {fourthParagraph}
      </>
    );
  };

  if (deal?.financial_info?.first_payment_date) {
    firstPaymentDate = getUTCDate(deal?.financial_info?.first_payment_date).toLocaleDateString();
  }
  return {
    [DealStateEnum.SentForSignatures]: <SentForSignatures deal={deal} />,
    [DealStateEnum.Signed]: (
      <>
        Great job getting those docs signed! We're already hard at work getting your loan funded by{' '}
        {bank}, but we'll update you with any news so you're not left in the dark. As soon as your
        funding is finalized,{' '}
        {isRefi
          ? "we'll start the process of titling and registration."
          : "we'll be emailing you letting you know."}
      </>
    ),
    [DealStateEnum.SendPayoff]: fundedAndSendPayoffDescription(),
    [DealStateEnum.Funded]: fundedAndSendPayoffDescription(),
    [DealStateEnum.WaitingForTitle]: waitingForTitleDescription(),
    [DealStateEnum.TitleReceived]: (
      <>
        Get excited - your auto title has arrived at Lease End HQ! OK, we admit that's probably not
        all that exciting to you but now our team is working hard to prepare your paperwork for
        final processing with the state. We're coordinating with the DMV, on your behalf, to make
        sure we have everything necessary for smooth sailing.
        <br />
        <br />
        Before we proceed, please take a moment to double (or triple) check your documents. Is
        anything expiring soon? Have you recently moved (or plan to soon)? Any changes to your name?
        <br />
        <br />
        If so, please update us by calling <Link href={`tel:+1-${phoneNumber}`}>
          {phoneNumber}
        </Link>{' '}
        or emailing <Link href={`mailto:${TITLES_EMAIL}`}>{TITLES_EMAIL}</Link>. Keeping your
        information up-to-date and fully accurate will help us avoid any unnecessary delays so you
        can get on with your life!
      </>
    ),
    [DealStateEnum.SentToProcessor]: (
      <>
        Exciting update! We've sent your title paperwork for final state approval and it's currently
        in the hands of the DMV. While we can't control the DMV and their processing times (as much
        as we wish we could), we've done everything we can on our end for the time being.
        <br />
        <br />
        As a reminder, to help prevent any unnecessary delays, be sure to keep your paperwork
        up-to-date. Let our title team know if any of your key documents (like insurance, driver's
        license, or registration) are expiring soon. We'll keep you posted on any further updates or
        requirements. Thank you again for choosing Lease End - we're in this together.
      </>
    ),
    [DealStateEnum.Booted]: '',
    [DealStateEnum.Finalized]: (
      <>
        Congratulations
        {isRefi
          ? ' on successfully refinancing your auto loan!'
          : "! Your title is now 'perfected' and the car is officially yours."}{' '}
        As a reminder, your first payment is due on <b>{firstPaymentDate}</b>. It's been a pleasure
        helping you through the {isRefi ? 'refinancing' : 'lease buyout'} process, and we hope you
        enjoy the newfound freedom of {isRefi ? 'your new loan' : 'owning your car'}.
        <br />
        <br />
        Before we part ways, we'd love to hear about your experience. Would you mind writing a{' '}
        <Link isExternal href={GOOGLE}>
          review
        </Link>{' '}
        to provide feedback about your experience? Driver insights like yours are invaluable to our
        growth and improvement as a company, and they will help other drivers down the road, too.
        <br />
        <br />
        Either way, we just want to say thank you again for choosing to work with us. Enjoy your
        car, and drive safe!
      </>
    ),
  };
};

export const getDealStatusLabel = (dealState: DealStateEnum, isRefi: boolean) => {
  switch (dealState) {
    case DealStateEnum.Closing:
    case DealStateEnum.SentForSignatures:
      return 'Review & Sign';
    case DealStateEnum.Signed:
      return 'Awaiting Bank Funding';
    case DealStateEnum.SendPayoff:
    case DealStateEnum.Funded:
      return `${isRefi ? 'Previous Loan' : 'Lease Account'} Payoff`;
    case DealStateEnum.WaitingForTitle:
      return 'Awaiting Title';
    case DealStateEnum.TitleReceived:
      return 'Title Received';
    case DealStateEnum.SentToProcessor:
      return 'Title Processing';
    case DealStateEnum.Booted:
    case DealStateEnum.Finalized:
      return 'CONGRATS!';
    default:
      return '';
  }
};

export const getDealStatusTooltip = (dealState: DealStateEnum, isRefi: boolean) => {
  switch (dealState) {
    case DealStateEnum.Closing:
    case DealStateEnum.SentForSignatures:
      return "You'll securely review and sign your prepared documents.";
    case DealStateEnum.Signed:
      return "We'll send your documents off and wait for the lending bank's approval & release of funds for your loan.";
    case DealStateEnum.SendPayoff:
    case DealStateEnum.Funded:
      return `Once your loan is funded, we'll use those funds to pay off your ${
        isRefi ? 'loan' : 'lease'
      } with your ${isRefi ? 'previous lender' : 'lienholder'}.`;
    case DealStateEnum.WaitingForTitle:
      return `After the ${isRefi ? 'loan' : 'lease'} is paid off with your ${
        isRefi ? 'previous lender' : 'lienholder'
      }, we'll wait for them to send us your vehicle title for processing.`;
    case DealStateEnum.TitleReceived:
      return 'Upon receiving your vehicle title, we prepare all the necessary paperwork for processing it with your state.';
    case DealStateEnum.SentToProcessor:
      return isRefi
        ? 'Your title and paperwork have been submitted for final processing.'
        : 'We send the vehicle title and paperwork packet to the state DMV for final processing.';
    case DealStateEnum.Booted:
    case DealStateEnum.Finalized:
      return `You're all done! Your title is now in the hands of your ${
        isRefi
          ? 'new lender.'
          : 'lender, your lease is paid off, and the car is yours to keep driving.'
      }`;
    default:
      return '';
  }
};

interface DealStatusDescriptionParams {
  deal?: DealWithFarthestState;
  requiredDocs?: DocumentType[];
}

export const dealStatusDescription = ({ deal, requiredDocs }: DealStatusDescriptionParams) => {
  const dealState = deal?.farthestState as DealStateEnum;

  if (!dealState) {
    return '';
  }

  return dealStatusDescriptions({ deal, requiredDocs })[dealState] ?? '';
};
