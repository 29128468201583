// sort-imports-ignore
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import Loader from '../components/Loader';
import AuthenticatedRoute from '../components/routeComponents/AuthenticatedRoute';
import LERoute from '../components/routeComponents/LERoute';
import { isLocal } from '../config';
import { LDFlags } from '../constants/flags';
import {
  ACCOUNT_LOGIN,
  CREATE_ACCOUNT,
  DASHBOARD,
  FEEDBACK,
  FLOW_ENTRY,
  FORGOT_PASSWORD,
  HOME,
  LINK_MY_ACCOUNT,
  MKT_HOME,
  ONE_CLICK_LOGIN,
  PAYMENTS_DASHBOARD,
  PAYOFF_REENTER,
  REDIRECT_URLS,
  RESET_PASSWORD,
  SECURE_DATA_ENTRY,
} from '../constants/urls';
import { useFlag } from '../hooks';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import useInactivityLogout from '../hooks/useInactivityLogout';
import PageFor404s from '../pages/404Page';
import AccountLogin from '../pages/auth/AccountLogin';
import CreateAccount from '../pages/auth/CreateAccount';
import ForgotPassword from '../pages/auth/ForgotPassword';
import LinkMyAccount from '../pages/auth/LinkMyAccount';
import OneClickLogin from '../pages/auth/OneClickLogin';
import ResetPassword from '../pages/auth/ResetPassword';
import FlowEntry from '../pages/flowEntryPages/FlowEntry';
import PayoffReenter from '../pages/flowEntryPages/PayoffReenter';
import Auth from '../pages/layouts/Auth';
import Main from '../pages/layouts/Main';
import MainFlow from '../pages/layouts/MainFlow';
import FlowRoutes from './FlowRoutes';
import Feedback from '../pages/Feedback/Feedback';
import SecureDataEntry from '../pages/secureDataEntry/SecureDataEntry';
// import ImageLibrary from './pages/ImageLibrary';

const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));

const Routes: React.FC = () => {
  useInactivityLogout();
  const { search } = useLocation();
  const [, setQueryParams] = useCookie(CookieKeys.QUERY_PARAMS);

  const inDashVerification = useFlag(LDFlags.SSN_DASH_VERIFICATION);
  const secureInfoLinkEnabled = useFlag(LDFlags.SECURE_INFO_LINK);

  useEffect(() => {
    if (search) {
      setQueryParams(search);
    }
  }, [search]);

  return (
    <Switch>
      <Route
        exact
        path={HOME}
        component={() => {
          if (isLocal) {
            window.location.replace(FLOW_ENTRY);
          } else {
            window.location.replace(`${MKT_HOME}${search}`);
          }

          return null;
        }}
      />
      {Object.entries(REDIRECT_URLS).map(([key, value]) => (
        <Route
          key={key}
          path={key}
          component={() => {
            window.location.replace(value);
            return null;
          }}
        />
      ))}
      <Route path="/flow/:path?">
        <MainFlow>
          <Suspense fallback={<Loader hideText mt={8} />}>
            <FlowRoutes />
          </Suspense>
        </MainFlow>
      </Route>
      <AuthenticatedRoute
        path={[DASHBOARD, PAYMENTS_DASHBOARD]}
        component={Dashboard}
        title="Dashboard | Lease End"
        exact
      />
      <Route
        path={[
          ACCOUNT_LOGIN,
          FORGOT_PASSWORD,
          RESET_PASSWORD,
          ONE_CLICK_LOGIN,
          CREATE_ACCOUNT,
          LINK_MY_ACCOUNT,
          FEEDBACK,
          ...(secureInfoLinkEnabled ? [SECURE_DATA_ENTRY] : []),
        ]}
      >
        <Auth>
          <Suspense fallback={<Loader hideText mt={8} />}>
            <Switch>
              <LERoute title="Feedback | Lease End" path={FEEDBACK} component={Feedback} />
              {secureInfoLinkEnabled && (
                <LERoute
                  title="Secure Data Entry | Lease End"
                  path={SECURE_DATA_ENTRY}
                  component={SecureDataEntry}
                />
              )}
              <LERoute path={ACCOUNT_LOGIN} component={AccountLogin} title="Login | Lease End" />
              <LERoute
                path={FORGOT_PASSWORD}
                component={ForgotPassword}
                title="Forgot Password | Lease End"
              />
              <LERoute
                path={RESET_PASSWORD}
                component={ResetPassword}
                title="Reset Password | Lease End"
              />
              <LERoute
                path={ONE_CLICK_LOGIN}
                component={OneClickLogin}
                title="One Click Login | Lease End"
              />
              <LERoute
                path={CREATE_ACCOUNT}
                component={CreateAccount}
                title="Create Account | Lease End"
              />
              {!inDashVerification && (
                <LERoute
                  path={LINK_MY_ACCOUNT}
                  component={LinkMyAccount}
                  title="Link My Account | Lease End"
                />
              )}
            </Switch>
          </Suspense>
        </Auth>
      </Route>
      <Route>
        <Main>
          <Suspense fallback={<Loader hideText mt={8} />}>
            <Switch>
              {/* Uncomment this to see what images have been added to COM */}
              {/* <LERoute path="/image-library" component={ImageLibrary} title="Image Library" /> */}
              <LERoute
                path={PAYOFF_REENTER}
                component={PayoffReenter}
                title="Payoff | Lease End"
                exact
              />
              <LERoute path={FLOW_ENTRY} component={FlowEntry} title="Flow | Lease End" exact />
              <LERoute path="*" component={PageFor404s} title="404 | Lease End" />
            </Switch>
          </Suspense>
        </Main>
      </Route>
    </Switch>
  );
};

export default Routes;
