import { Box, Center, Collapse, Divider, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import ButtonedRadioGroup from '../../../components/ButtonedRadioGroup';
import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { Label } from '../../../components/formComponents';
import Input from '../../../components/formComponents/Input';
import NumberInput from '../../../components/formComponents/NumberInput';
import MonthsAndYears, {
  shouldRequirePrevious,
} from '../../../components/monthsAndYears/MonthsAndYears';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import {
  EmploymentStatusType,
  useTemporaryInfoUpdateMutation,
} from '../../../gql/generated/graphql';
import { useTempInfo } from '../../../hooks';
import { getTimeAtLabel } from '../../../utils/customer';
import {
  EmploymentFormValues,
  employmentInitialValues,
  employmentValidationSchema,
} from '../utils';

const Employment = () => {
  const { info } = useTempInfo();
  const tempInfoData = info?.data;
  const [showPreviousEmployment, setShowPreviousEmployment] = useState<boolean>(false);

  useEffect(() => {
    setShowPreviousEmployment(
      shouldRequirePrevious(tempInfoData?.years_at_job, tempInfoData?.months_at_job ?? 0),
    );
  }, [tempInfoData]);

  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();

  const { updateState, index, setOpenAccordionIndex } = useLEAccordionItemContext();

  const onSubmitForm = async (values: EmploymentFormValues) => {
    updateState({ status: 'loading' });

    const data = {
      employment_status: values.employment_status,
      employer_name: values.employer_name.slice(0, 25).trim(),
      employer_phone_number: values.employer_phone_number,
      job_title: values.job_title.slice(0, 20).trim(),
      years_at_job: values.years_at_job,
      months_at_job: values?.months_at_job ?? 0,
      salary: values.salary,
      pay_frequency: values.pay_frequency,
      previous_employer_name: values.previous_employer_name.slice(0, 25).trim(),
      previous_employer_phone_number: values.previous_employer_phone_number,
      previous_job_title: values.previous_job_title.slice(0, 20).trim(),
      previous_years_at_job: values.previous_years_at_job,
      previous_months_at_job: values?.previous_months_at_job ?? 0,
    };

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data,
        },
      },
    });

    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  if (!tempInfoData) {
    return null;
  }

  return (
    <Box>
      <Center mb={{ base: 4, md: 0 }}>
        <TextWithTooltip label="Verifying your employment history gives lenders information about your regular income source(s) and helps them assess risk.">
          Why do we need this?
        </TextWithTooltip>
      </Center>
      <Formik
        enableReinitialize
        initialValues={employmentInitialValues(tempInfoData)}
        validationSchema={employmentValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => {
          const hasErrors = Object.values(errors).some((value) => value !== '');
          const isEmployed = values?.employment_status === EmploymentStatusType.Employed;

          return (
            <Form>
              <Label mt={5}>EMPLOYMENT STATUS</Label>
              <ButtonedRadioGroup
                columns={3}
                collapseOnMobile={false}
                onChange={(value) => setFieldValue('employment_status', value)}
                options={[
                  { label: 'EMPLOYED', value: EmploymentStatusType.Employed },
                  { label: 'NOT EMPLOYED', value: EmploymentStatusType.NotEmployed },
                  { label: 'RETIRED', value: EmploymentStatusType.Retired },
                ]}
                value={values.employment_status}
                name="employment_status"
                defaultValue={EmploymentStatusType.Employed}
                mb={8}
              />
              {isEmployed && (
                <>
                  <Flex flexDir={{ base: 'column', md: 'row' }} mb={4}>
                    <Input
                      label="EMPLOYER NAME"
                      name="employer_name"
                      _container={{ mr: { base: 0, md: 2 }, mb: { base: 4, md: 0 } }}
                      maxLength={50}
                    />
                    <Input
                      label="EMPLOYER PHONE"
                      name="employer_phone_number"
                      mask="(999) 999-9999"
                      type="tel"
                      _container={{ ml: { base: 0, md: 2 } }}
                    />
                  </Flex>
                  <Input label="JOB TITLE" name="job_title" mb={4} maxLength={50} />
                </>
              )}

              <NumberInput
                name="salary"
                isMoney
                type="decimal"
                _input={{ inputMode: 'numeric' }}
                label="ANNUAL GROSS INCOME"
                mb={2}
              />
              <TextWithTooltip label="Please provide your personal income information only. Include all sources of income such as employment earnings, self-employment income, investments, retirement funds, etc. This information should only reflect the income of the main applicant—do not include household or combined income.">
                Multiple sources of income?
              </TextWithTooltip>
              <Divider my="20px" />
              <Label mb={3}>
                {getTimeAtLabel(values.employment_status as EmploymentStatusType)}
              </Label>
              <MonthsAndYears
                setShow={setShowPreviousEmployment}
                yearsName="years_at_job"
                monthsName="months_at_job"
                values={values}
                mb={showPreviousEmployment ? 4 : 0}
              />
              <Collapse in={showPreviousEmployment}>
                <Box
                  w="100%"
                  mb={2}
                  p="8px"
                  borderRadius="xl"
                  bgColor="beige"
                  fontSize={{ base: '10px', md: '12px' }}
                >
                  You've been here for <b>less than two years</b>. Where did you work before?{' '}
                  <TextWithTooltip
                    textProps={{
                      fontSize: { base: '10px', md: '12px' },
                      cursor: showPreviousEmployment ? 'help' : 'default',
                    }}
                    label="To assess risk and establish a regular history of income, lenders prefer looking at more than two years' worth of data."
                  >
                    (Why does this matter?)
                  </TextWithTooltip>
                </Box>
                <Box>
                  <Flex flexDir={{ base: 'column', md: 'row' }} mb={4} mt={4}>
                    <Input
                      label="PREVIOUS EMPLOYER NAME"
                      name="previous_employer_name"
                      _container={{ mr: { base: 0, md: 2 }, mb: { base: 4, md: 0 } }}
                    />
                    <Input
                      label="PREVIOUS EMPLOYER PHONE"
                      name="previous_employer_phone_number"
                      mask="999-999-9999"
                      type="tel"
                      _container={{ ml: { base: 0, md: 2 } }}
                    />
                  </Flex>
                  <Input label="PREVIOUS JOB TITLE" name="previous_job_title" />
                  <Label mt={6} mb={3}>
                    TIME AT THIS EMPLOYER?
                  </Label>
                  <MonthsAndYears
                    yearsName="previous_years_at_job"
                    monthsName="previous_months_at_job"
                    mb={12}
                  />
                </Box>
              </Collapse>

              <Center>
                <PrimaryButton
                  mt="30px"
                  mb={{
                    base: '20px',
                    lg: '0',
                  }}
                  loading={isSubmitting}
                  type="submit"
                />
              </Center>
              {hasErrors && (
                <Text color="leaseEndRed" textAlign="center" mt={{ base: 0, lg: 4 }}>
                  Review fields above for errors.
                </Text>
              )}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Employment;
