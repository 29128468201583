import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import Modal from '../../../components/Modal';
import { DealType } from '../../../gql/generated/graphql';
import { DealWithFarthestState } from '../utils';

interface Props {
  deal: DealWithFarthestState;
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeModal = ({ deal, isOpen, onClose }: Props) => {
  const isRefi = deal?.type === DealType.Refi;
  const isCobuyer = deal?.isCobuyer;

  return (
    <Modal
      title={`Welcome to Your ${isCobuyer ? 'Co-Buyer ' : ''}Dashboard`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex direction="column" textAlign="center" alignItems="center">
        <Text>
          This is it - the perfect place to check on your {isRefi ? 'refinance' : 'lease-end'}{' '}
          progress. As your Lease End Success Team continues working{' '}
          {isRefi ? 'on your new auto loan' : 'to end your lease'}, you'll see which stage you're in
          (and what comes next) here. Your team will be in touch if we need anything else from you.
          In the meantime, have a look around!
        </Text>
        <Button mt="20px" onClick={onClose} w="fit-content">
          OK
        </Button>
      </Flex>
    </Modal>
  );
};

export default WelcomeModal;
