interface Config {
  domain: string;
  baseUrl: string;
  mktUrl: string;
  auth0: {
    internalDomain: string;
    externalDomain: string;
    externalClientId: string;
    audience: string;
  };
  urls: {
    appRoot: string;
    apiRoot: string;
    wsRoot: string;
    prsApiRoot: string;
  };
  launchDarklyClientId: string;
  gtm: {
    gtmId: string;
    auth: string;
    preview: string;
  };
  rudderstack: {
    writeKey: string;
    dataPlaneUrl: string;
    cookieDomain?: string;
  };
  finix: {
    environment: string;
    merchantId: string;
    applicationId: string;
  };
}
type Environments = 'test' | 'development' | 'staging' | 'production';

type Configs = {
  [env in Environments]: Config;
};

// this helps test locally on mobile devices
// replace with your ip address; Mac: open terminal and type `ipconfig getifaddr en0`
// You'll need to also add this the baseUrl(http://${devBase}:3001) to the Application URIs/Allowed Origins in the auth0 dashboard
const devBase = 'localhost';

const configs: Configs = {
  test: {
    domain: 'leaseend.com',
    baseUrl: 'https://my.staging.leaseend.com',
    mktUrl: 'https://staging.leaseend.com',
    auth0: {
      internalDomain: 'auth.leaseend.com',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      appRoot: 'https://localhost:3000',
      apiRoot: 'http://localhost:8080',
      wsRoot: 'ws://localhost:8080',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
    },
    launchDarklyClientId: '',
    gtm: {
      gtmId: '',
      auth: '',
      preview: '',
    },
    rudderstack: {
      writeKey: '',
      dataPlaneUrl: '',
      cookieDomain: '',
    },
    finix: {
      environment: 'sandbox',
      merchantId: '',
      applicationId: '',
    },
  },
  development: {
    domain: devBase,
    baseUrl: `http://${devBase}:3001`,
    mktUrl: `http://${devBase}:3002`,
    auth0: {
      internalDomain: 'auth.leaseend.com',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      appRoot: `http://${devBase}:3000`,
      apiRoot: `http://${devBase}:8080`,
      wsRoot: `ws://${devBase}:8080`,
      prsApiRoot: 'https://payoff.staging.leaseend.com',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
    gtm: {
      gtmId: 'GTM-PSLHHMM',
      auth: 'bgchO0mYpBcZfNkiuwRMpg',
      preview: 'env-4',
    },
    rudderstack: {
      writeKey: '2TgHgjnec2WRfo63YDTAzuF03D0',
      dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
      cookieDomain: '',
    },
    finix: {
      environment: 'sandbox',
      merchantId: 'MUeDVrf2ahuKc9Eg5TeZugvs',
      applicationId: 'APagXdeXhvL7MkwTcp8rJyZE',
    },
  },
  staging: {
    domain: 'leaseend.com',
    baseUrl: 'https://my.staging.leaseend.com',
    mktUrl: 'https://staging.leaseend.com',
    auth0: {
      internalDomain: 'auth.leaseend.com',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      appRoot: 'https://app.staging.leaseend.com',
      apiRoot: 'https://api.staging.leaseend.com',
      wsRoot: 'wss://api.staging.leaseend.com',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
    gtm: {
      gtmId: 'GTM-PSLHHMM',
      auth: 'ulxXBrhuMcRevnSvDodg3A',
      preview: 'env-5',
    },
    rudderstack: {
      writeKey: '2TgHgjnec2WRfo63YDTAzuF03D0',
      dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
      cookieDomain: '.leaseend.com',
    },
    finix: {
      environment: 'sandbox',
      merchantId: 'MUeDVrf2ahuKc9Eg5TeZugvs',
      applicationId: 'APagXdeXhvL7MkwTcp8rJyZE',
    },
  },
  production: {
    domain: 'leaseend.com',
    baseUrl: 'https://my.leaseend.com',
    mktUrl: 'https://www.leaseend.com',
    auth0: {
      internalDomain: 'auth.leaseend.com',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      appRoot: 'https://app.leaseend.com',
      apiRoot: 'https://api.leaseend.com',
      wsRoot: 'wss://api.leaseend.com',
      prsApiRoot: 'https://payoff.leaseend.com',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e2',
    gtm: {
      gtmId: 'GTM-PSLHHMM',
      auth: 'AkAE3X1T7WsBjCHxN-ROwQ',
      preview: 'env-1',
    },
    rudderstack: {
      writeKey: '2ThxH3bH4rZEYGkKriUCMUIrp5D',
      dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
      cookieDomain: '.leaseend.com',
    },
    finix: {
      environment: 'live',
      merchantId: 'MUcpr1YX6Lk8YuDaQxdabnhe',
      applicationId: 'AP8u5B6AVU37pBzHsM4TrnLN',
    },
  },
};

export const getEnv = () => process.env.REACT_APP_STAGE || process.env.NODE_ENV;
export const isLocal = getEnv() === 'development';
export const isStaging = getEnv() === 'staging';
export const isProd = getEnv() === 'production';

export default configs[getEnv() as Environments];
